// colors
$blue: #233963;
$white: #fff;
$black: #000;
$dark: #4a4a4a;
$light: #a4a4a4;
$orange: #ef9536;

//font size
$head_title_size_web_en: 50px;
$head_title_size_mobile_en: 32px;
$head_title_size_web_ar: 45px;
$head_title_size_mobile_ar: 32px;
$head_dec_size_web_en: 20px;
$head_dec_size_mobile_en: 16px;
$head_dec_size_web_ar: 18px;
$head_dec_size_mobile_ar: 16px;

$all_title_size_web_en: 40px;
$all_title_size_mobile_en: 30px;
$all_title_size_web_ar: 36px;
$all_title_size_mobile_ar: 26px;
$all_dec_size_web_en: 20px;
$all_dec_size_mobile_en: 16px;
$all_dec_size_web_ar: 18px;
$all_dec_size_mobile_ar: 16px;

$box_title_size_web_en: 20px;
$box_title_size_mobile_en: 20px;
$box_title_size_web_ar: 20px;
$box_title_size_mobile_ar: 20px;
$box_dec_size_web_en: 16px;
$box_dec_size_mobile_en: 16px;
$box_dec_size_web_ar: 16px;
$box_dec_size_mobile_ar: 16px;

.app:lang(ar) {
  direction: rtl;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
*,
html,
body {
  box-sizing: border-box;
  font-family: "Noto Kufi Arabic", sans-serif;
  font-style: normal;
}

[dir="rtl"] {
  *,
  html,
  body {
    font-style: normal;
  }
}
a {
  color: #3f546c;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul,
ol {
  padding-right: 24px !important;
}
.grid {
  display: grid;
}
.flex {
  display: flex;

  &.col {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
  }
  &.row-reverse {
    flex-direction: row-reverse;
  }
  &.content-center {
    justify-content: center;
  }
  &.content-space-between {
    justify-content: space-between;
  }
  &.content-space-around {
    justify-content: space-around;
  }
  &.items-center {
    align-items: center;
  }
  &.items-start {
    align-items: flex-start;
  }
  &.items-end {
    align-items: flex-end;
  }
  &.grid {
    flex-wrap: wrap;
  }
  &.grid-reverse {
    flex-wrap: wrap-reverse;
  }

  .grow {
    flex-grow: 1;
  }
}
.d-md-none {
  display: none;
}
.text-center{
  text-align: center;
}
.rtl{
  direction: rtl
}
.padding-3{
  padding: 0 1.5rem;
}
.padding-2{
  padding: 0 1rem
}
@import 'config.scss';

@import './components/nav.scss';

@import "./home.scss";
@import "./mercahnt.scss";
@import "./biller.scss";
@import "./consumer.scss";
@import "./about.scss";
@import "./whatpay.scss";
@import "./whypay.scss";
// @import "./clients.scss";
@import "./blogs.scss";
@import "./contact-us.scss";

@import './components/footer.scss';
 
.top-content {
  border-bottom-right-radius: 30%;
  border-radius: 0 0 50% 50% / 45px;
  background: linear-gradient(180deg, #51358a 0%, #5d49c9 35%, #6252df 100%);
  margin-bottom: 2rem;
  @media only screen and (max-width: 768px) {
      margin-bottom: 1rem;
      padding-bottom: 2rem;
  }

  .head-content {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 1rem;
      @media only screen and (max-width: 768px) {
          display: block;
      }
    }
}
.pos-empowerd {
  .tracking-transaction{
      background-image: url('./images/getcashi-background.png')
  }
}

.top-content {
    @media (max-width : 768px) {
    padding-bottom: 3rem;
  }
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  .navbar-dark .navbar-toggler{
    background: #583fb0;
  }
  .navbar-nav .nav-item .nav-link{
    color: #583fb0;
  }
}

body.offcanvas-active {
  overflow: hidden;
}

.offcanvas-header {
  display: none;
}

.screen-overlay {
  width: 0%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity 0.2s linear, visibility 0.1s, width 1s ease-in;
}
.screen-overlay.show {
  transition: opacity 0.5s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

#Partners .top-content h1 {
  text-align: center;
  color: #fff;
  padding: 2rem 0;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}
[lang="ar"] #Partners .top-content h1 {
  margin-bottom: 1rem;
}