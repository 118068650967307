
.navbar {
	background-color: transparent;
	color: #fff;
	padding: 10px 20px;
	z-index: 5;
  }
  
  .navbar-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
  }
  
  .nav-links {
	list-style: none;
	display: flex;
    align-items: center;
  }
  
  .nav-links li {
	margin-right: 20px;
  }
  
  .nav-links li a {
	text-decoration: none;
	color: #fff;
  }
  .sticky .nav-links li a{
	color: #583fb0;
  }
  .nav-links li a:hover {
    color: #f8b132;
  }
.nav-links li a:after {
    content: "";
    background: #f8b132;
    width: 0;
    height: 3px;
    margin-top: 0;
    transition: width .3s;
    display: block;
}
.nav-links li a:hover:after {
    width: 100%;
	}
  .menu-icon {
	display: none;
	flex-direction: column;
	cursor: pointer;
  }
  
  .bar {
	width: 25px;
	height: 3px;
	background-color: #fff;
	margin: 3px 0;
  }
.sticky .bar{
	background-color: #583fb0;
}
.lang{
	background-color: #feca00;
    border-radius: 10px;
	padding: 0.5rem;
	a{
		
	color: #000 !important;
	}
}
  @media screen and (max-width: 768px) {
	.nav-links {
		list-style: none;
		display: flex;
		position: fixed;
		top: 0;
		right: -100%;
		flex-direction: column;
		width: 250px;
		height: 100vh;
		background-color: #fff;
		padding: 4px !important;
		transition: right 0.3s ease;
		align-items: normal;
	}
	.navbar-container{
		justify-content: space-between;
	}
	.nav-links.active {
		right: 0;
		z-index: 1;
	}
	.menu-icon {
	  display: flex;
	}
	 
	.nav-links li {
		margin-right: 0;
        padding: 1rem;
        font-weight: 600;
        border-bottom: 1px solid #ddd;
	}
	.nav-links li a {
        color: #1976d2 !important;
    }
	.d-md-none{
		display: block;
	}
  }