.footer {
	padding: 0.5rem 5rem 0;
	position: absolute;
	width: 100%;
	bottom: 0;
	.grid{
		grid-template-columns: 1fr 1fr 1fr;
		justify-content: space-evenly;
		text-align: center;
		@media only screen and (max-width: 768px) {
			display: block;
		}
		.logos{
			display: flex;
			justify-content: space-evenly;
		}
		.links{
			a{
				margin-inline-end: 0.5rem;
			}
		}
	}
	@media only screen and (max-width: 768px) {
		position: relative;
	}

	>.flex {
		justify-content: space-between;
	}

	.list {
		margin-inline-end: 4rem;
		margin-right: 4rem;

		.list-item {
			width: 100%;

			a {
				color: #fff;
				text-decoration: none;
				display: block;
				line-height: 1.5em;
				width: fit-content;
				transition: 80ms ease;
				font-weight: 400;
				font-size: 15px;
				line-height: 24px;
				letter-spacing: -0.22px;
				color: #fff;
				flex: none;
				order: 1;
				flex-grow: 0;

				&:hover {
					color: #fff;
				}
			}
		}
	}

	.menu {
		.title {
			color: #fff;
			font-weight: bold;
			line-height: 2em;
		}
	}

	.logo {
		.image {
			width: 7rem;
			height: 7rem;
		}
	}

	.copyright {
		padding: 0.5rem 0;
		color: #fff;
		border-top: 1px solid rgba(0, 0, 0, 0.3);
		margin-top: 1rem;
		font-weight: 400;
		font-size: 15px;
		line-height: 24px;
		letter-spacing: -0.22px;
		color: #fff;
		flex: none;
		order: 1;
		flex-grow: 0;

		p {
			margin: 0;
			text-align: center;
		}
	}
}

.footer {
	.copyright {
		a {
			font-weight: 400;
			font-size: 15px;
			line-height: 24px;
			letter-spacing: -0.22px;
			color: #fff;
			flex: none;
			order: 1;
			flex-grow: 0;
		}
	}
}

@media (max-width: 480px) {
	#footer .row>div,#footer-pages .row>div{
		margin: 0.5rem 0;
	}
	.footer {
		padding: 2rem;
		text-align: center;

		.list {
			margin: 0 0 1rem !important;
		}

		.flex {
			flex-direction: column;
		}



		.menu {
			.title {
				text-align: start;
			}
		}
	}
}
.new-footer{
	border-top: 1px solid #ddd;
	.grid{
		padding: 1rem 3rem;
		grid-template-columns: 1fr 1fr 1fr;
		justify-content: space-evenly;
		text-align: center;
		@media only screen and (max-width: 768px) {
			display: block;
		}
		.logos{
			display: flex;
			justify-content: space-between;
		}
		.links{
			a{
				margin-inline-end: 0.5rem;
			}
		}
	}
}