#about {

    .top-content {
        .head-content {

             .description {
                width: 37%;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                }

                h1 {
                    
                    font-style: normal;
                    font-weight: 700;
                    font-size: $head_title_size_web_en;
                    line-height: 56px;
                    color: #fff;

                    @media only screen and (max-width: 768px) {
                        font-size: $head_title_size_mobile_en;
                        line-height: 35px;
                    }
                }

                p {
                    
                    font-style: normal;
                    font-weight: 500;
                    font-size: $head_dec_size_web_en;
                    line-height: 30px;
                    color: #ffffff;

                    @media only screen and (max-width: 768px) {
                        font-size: $head_dec_size_mobile_en;
                        line-height: 24px;
                    }

                    span {
                        font-size: 20px;
                        margin-top: 1rem;
                        display: block;
                        color: #feca00;

                        @media only screen and (max-width: 768px) {
                            font-size: 14px;
                        }
                    }
                }
            }

            .image {
                width: 23%;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                    display: none;
                }

                img {
                    width: 100%
                }
            }
        }
    }

    .content {
        .cashi{
            &.grid{
                grid-template-columns: repeat(3,1fr);
                justify-content: space-between;
                margin:5rem auto;
                text-align: center;
                width: 80%;
                @media only screen and (max-width: 768px) {
                    display: block;
                    width: 90%;
                    margin: 1rem auto;
                }
            }
            .about_card {
                h5 {
                    
                    font-style: normal;
                    font-weight: 500;
                    font-size: $box_title_size_web_en;
                    line-height: 34px;
                    text-align: center;
                    color: #0F1C49;
                    margin-bottom: 0.5rem;

                    @media only screen and (max-width: 768px) {
                        font-size: $box_title_size_mobile_en;
                    }
                }

                p {
                    
                    font-style: normal;
                    font-weight: 400;
                    font-size: $box_dec_size_web_en;
                    line-height: 24px;
                    color: #130F49;

                    @media only screen and (max-width: 768px) {
                        font-size: $box_dec_size_mobile_en;
                        line-height: 20px;
                    }
                }
            }
        }
    }

}
[lang="ar"] {
    #about .top-content .head-content .description h1 {
        font-size: $head_title_size_web_ar;
        margin-bottom: 1rem;
        @media only screen and (max-width: 768px) {
            font-size: $head_title_size_mobile_ar;
            line-height: 30px;
        }
    }
    #about .top-content .head-content .description p {
        font-size: $head_dec_size_web_ar;
        line-height: 30px;
        @media only screen and (max-width: 768px) {
            font-size: $head_dec_size_mobile_ar;
            line-height: 26px;
        }
    }
    
    #about .content .about_card h5{
        font-size: $box_title_size_web_ar;
        @media only screen and (max-width: 768px) {
            font-size: $box_title_size_mobile_ar;
        }
    }
    #about .content .about_card p{
        font-size: $box_dec_size_web_ar;
        @media only screen and (max-width: 768px) {
            font-size: $box_dec_size_mobile_ar;
        }
    }
}