#contact-us {
    
    .top-content {
        .head-content {


            .description { 
                h1 {
                    
                    font-style: normal;
                    font-weight: 700;
                    //margin:0 4rem;
                    font-size: $head_title_size_web_en;
                    line-height: 56px;
                    color: #fff;
                    @media only screen and (max-width: 768px) {
                        font-size: $head_title_size_mobile_en;
                        line-height: 35px;
                        margin:0;
                        text-align: center;
                    }
                }
 
            }

            .image {
                width: 18%;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                    display: none;
                }

                img {
                    width: 100%
                }
            }
        }
    }
    .contact_with{
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
    }
    
    .info-contact{
        padding: 2rem;
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
            @media only screen and (max-width: 768px) {
                padding: 4rem 0;
                flex-wrap: wrap;
            }
        .image{
            @media only screen and (max-width: 768px) {
                display: none;
            }
            }
        p{
            color: #4A4A4A;

            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            
            @media only screen and (max-width: 768px) {
                width: 100%;
                margin-bottom: 2rem;
            }
            .social{
                margin-bottom: 0.5rem;
            }
            .email{
                color: #F8B132;
                font-weight: bold;
            }
            .phone{
                color: #F8B132;
                font-weight: bold;
            }
        }
    }
}

#get-cashi{
    .v-text-field--outlined.v-input--dense .v-label{
        left:auto !important
    }
    .top-content {
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('./images/cashi-background.png');
        border-radius: 0;
        margin-bottom: 0;
        min-height: 100vh;
        @media only screen and (max-width: 768px) {
            // margin-bottom: 1rem;
            min-height: auto;
        }
        .head-content {

            @media only screen and (max-width: 768px) {
                padding: 1rem;
            }

            .description {
                // width: 33%;

                @media only screen and (max-width: 768px) {
                    // width: 80%;
                    // display: none;
                }
                h1 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 56px;
                    color: #fff;
                    @media only screen and (max-width: 768px) {
                        font-size: $head_title_size_mobile_en;
                        line-height: 35px;
                        margin: 0;
                        text-align: start;
                    }
                }
                p{
                    margin-bottom: 1rem;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 34px;
                    letter-spacing: 0.0052em;
                    color: #FFFFFF;
                }
            }
            .v-btn__content{
                color: #fff;
            }
            .img {
                display:flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                img {
                    width: 65%;
                    @media only screen and (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
        .footer{
            position: relative;
        }
    }
    .get-cashi-now-form{
        .name{

            overflow: hidden;
        }

        .phone{

            #phone{
                padding: 1rem 0.75rem 1rem 3.75rem;
                &.valid{border: 2px solid green}
                &.notvalid{ border: 2px solid red}
            }
            label{
                color: rgba(var(--bs-body-color-rgb), .65);
                transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
            }
        }
        .submit{
            width: 100%;
            background: #FECA00;
            border-color: #FECA00;
            box-shadow: none;
            padding: 0.75rem;
            color: #130F49;
        }
    }

}
[dir="ltr"]{
    #get-cashi .get-cashi-now-form .phone label{
        transform: scale(.85) translateY(-0.75rem) translateX(4rem);
    }
    #get-cashi .get-cashi-now-form #lable_name{
        font-size: 12px;
        line-height: normal;
        white-space: normal;
    
    }
}
