#whatpay {
    
    .top-content {
        .head-content {


            .description {
                width: 37%;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                }

                h1 {
                    
                    font-style: normal;
                    font-weight: 700;
                    font-size: $head_title_size_web_en;
                    line-height: 56px;
                    color: #fff;

                    @media only screen and (max-width: 768px) {
                        font-size: $head_title_size_mobile_en;
                        line-height: 35px;
                    }
                }

                p {
                    
                    font-style: normal;
                    font-weight: 500;
                    font-size: $head_dec_size_web_en;
                    line-height: 30px;
                    color: #ffffff;

                    @media only screen and (max-width: 768px) {
                        font-size: $head_dec_size_mobile_en;
                        line-height: 24px;
                    }

                    span {
                        font-size: 20px;
                        margin-top: 1rem;
                        display: block;
                        color: #feca00;

                        @media only screen and (max-width: 768px) {
                            font-size: 14px;
                        }
                    }
                }
            }

            .image {
                width: 20%;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                    display: none;
                }

                img {
                    width: 100%
                }
            }
        }
    }

    .content {
        .card {
            background: #F5F7F9;
            border: 1px solid #CDE4F9;
            border-radius: 24px;
            padding: 1rem;
            margin: 1rem auto;
            width: 85%;
            h5 {
                
                font-style: normal;
                font-weight: 500;
                font-size: $box_title_size_web_en;
                line-height: 28px;
                color: #665AF0;
                margin-bottom: 0.5rem;

                @media only screen and (max-width: 768px) {
                    font-size: $box_title_size_mobile_en;
                }
            }

            p {
                
                font-style: normal;
                font-weight: 400;
                font-size: $box_dec_size_web_en;
                line-height: 30px;
                color: #130F49;

                @media only screen and (max-width: 768px) {
                    font-size: $box_dec_size_mobile_en;
                    line-height: 20px;
                }
            }
        }

        .services {
            grid-template-columns: repeat(4,1fr);
            grid-gap: 1rem;
            width: 80%;
            margin:2rem auto;
            text-align: center;
            @media only screen and (max-width: 768px) {
                width: 92%;
                grid-template-columns: repeat(2,1fr);
            }
            .card {
                padding: 1rem;
                background: rgba(96, 78, 213, 0.05);
                border-radius: 15px;
            }
        }
    }

}
[lang="ar"] {
    #whatpay .top-content .head-content .description h1 {
        font-size: $head_title_size_web_ar;
        margin-bottom: 1rem;
        @media only screen and (max-width: 768px) {
            font-size: $head_title_size_mobile_ar;
            line-height: 30px;
        }
    }
    #whatpay .top-content .head-content .description p {
        font-size: $head_dec_size_web_ar;
        line-height: 30px;
        @media only screen and (max-width: 768px) {
            font-size: $head_dec_size_mobile_ar;
            line-height: 26px;
        }
    }
    #whatpay .content .about_card h5{
        font-size: $box_title_size_web_ar;
        @media only screen and (max-width: 768px) {
            font-size: $box_title_size_mobile_ar;
        }
    }
    #whatpay .content .about_card p{
        font-size: $box_dec_size_web_ar;
        @media only screen and (max-width: 768px) {
            font-size: $box_dec_size_mobile_ar;
        }
    }
    #whatpay .content .card h5{

        font-size: $box_title_size_web_en;

        @media only screen and (max-width: 768px) {
            font-size: $box_title_size_mobile_en;
        }

    }
    #whatpay .content .card p{


        font-size: $box_dec_size_web_en;

        @media only screen and (max-width: 768px) {
            font-size: $box_dec_size_mobile_en;
            line-height: 20px;
        }
    }
}