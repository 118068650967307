#blogs{
    .grid{
        grid-template-columns: repeat(3,1fr);
        grid-gap: 1rem 2rem;
        justify-content: space-between;
        width: 75%;
        margin: auto;
        @media only screen and (max-width: 768px) {
            width: 95%;
            display: block;
        }
        .one-blog{
            .card {
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                overflow: hidden;
                margin: 20px;
              }
            
              .card img {
                width: 100%;
                max-height: 131px;
                object-fit: cover;
                border-radius: 8px 8px 0 0;
              }
            
              .card-content {
                padding: 20px;
              }
            
              .card-title {
                font-size: 20px;
                margin-bottom: 10px;
              }
            
              .card-text {
                color: #555;
                line-height: 1.5;
              }
        }
    }
}